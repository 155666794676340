 <template>
    <div>
        <div class="row mx-2 mx-lg-4 mx-xl-5">
            <div class="col-12 col-md-6 pe-md-4">
                <h3 class="mt-3 mx-1 text-left">Card Search</h3>
                <div class="mt-4 mx-auto">
                    <Input
                        placeholder="Search Card by Name"
                        width="calc(100% - 70px)"
                        :display-img="cardSearchImgUrl"
                        v-model="cardSearchName"
                    ></Input>
                    <!-- <Select
                        :options="$asset.lol.elos"
                        v-model="cardSearchElo"
                        :border-radiuses="[0, '20px', '20px', 0]"
                        width='80px'
                        z-index="1"
                    ></Select> -->
                    <Button class="ms-2" style="min-width: 60px">
                        <Icon :size="16" name="chevron-right" />
                    </Button>
                </div>
                <div class="card-search-container cards-view-scrollable align-content-start mt-4">
                    <div class="row w-100 no-m">
                        <div class="col-12 col-md-6 py-1" v-for="card in filteredCards" :key="'search' + card.cardCode">
                            <Card
                                :code="card.cardCode"
                                height="35px"
                                width="100%"
                                variant="banner"
                                :link="true"
                            />
                        </div>
                    </div>
                </div>
                <VenatusAd class="mt-4 mx-2" v-if="$store.state.winWidth > 768" :height="150" />
                <VenatusAd class="mt-4 mx-2" v-else :height="100" />
            </div>
            <div class="col-12 col-md-6 ps-md-4 my-2 my-md-0">
                <h3 class="mt-3 mx-1 text-left">Card Library</h3>
                <div class="mt-4">
                    <Tab
                        class="mt-2"
                        :options="regionOptions"
                        v-model="tabRegion"
                    />
                    <div class="card-library-container cards-view-scrollable mt-4">
                        <div v-for="(cards, category) in regions[tabRegion]" :key="tabRegion + category">
                            <h6 class="text-left mx-2 capfirst">{{category}}:</h6>
                            <div class="row w-100 no-m mb-3">
                                <div class="col-12 col-md-6 py-1" v-for="card in cards" :key="tabRegion + card.cardCode">
                                    <Card
                                        :code="card.cardCode"
                                        height="35px"
                                        width="100%"
                                        variant="banner"
                                        :link="true"
                                    />
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<style lang="scss" scoped>
@import "@/scss/variables.scss";
@import "@/scss/mixins.scss";

.card-search-container {
    height: 35vh;
    overflow-y: scroll;
}

.card-library-container {
    height: 60vh;
    overflow-y: scroll;
}

@include media-breakpoints-down($md) {
    .card-search-container {
        height: 100px;
        overflow-y: scroll;
    }

    .card-library-container {
        height: auto;
        overflow-y: scroll;
    }
}

</style>

<script lang="ts">
import { Options, Vue } from 'vue-class-component'
import { Watch } from 'vue-property-decorator'
import Card from '@/components/lor/Card.vue'
import Tab from '@/components/Tab.vue'
import Input from '@/components/Input.vue'
import Button from '@/components/Button.vue'
import Icon from '@/components/Icon.vue'

interface RegionContent {
    champions: Array<any>
    abilities: Array<any>
    units: Array<any>
    spells: Array<any>
    landmarks: Array<any>
}

@Options({components: {Tab, Card, Input, Button, Icon}})
export default class LorCards extends Vue {

    regionLocals: Record<string, string> = {}
    regions: Record<string, RegionContent> = {}
    tabRegion = ""

    cardSearchName = ""
    cardSearchQualCode: string | null = null
    filteredCards: Array<any> = []

    get regionOptions (): Array<Record<string, any>> {
        const o: Array<Record<string, any>> = []
        for (const k of Object.keys(this.regionLocals)) {
            o.push({
                value: k,
                img: this.$cdn.url('lor', ['factions', k.toLowerCase()], 'png')
            })
        }
        return o
    }

    get cardSearchImgUrl (): string | null {
        if (this.cardSearchQualCode == null)
            return null
        return this.$cdn.url('lor', ['cards', 'circle', this.cardSearchQualCode], 'png')
    }

    mounted (): void {
        this.$dom.hidePopperOnScroll('.cards-view-scrollable')
    }

    created (): void {
        this.parseCards()
        this.tabRegion = this.regionOptions[parseInt(this.$route.query.regionIndex?.toString() || "0")].value
        this.cardSearchName = this.$route.query.search?.toString() || ""
        this.cardSearchNameChanged(this.cardSearchName)
        this.$meta.use({
            title: 'Card Library, Master Decks Search | StatHub.gg LoR',
            og: {
                image: this.$cdn.url('lor', ['cards', 'square', '02BW026'], '.png'),
                description: 'Legends of Runeterra card library from all regions and sets with up to date info and master decks.'
            }
        })
    }

    @Watch('cardSearchName')
    cardSearchNameChanged (after: string): void {
        let id = this.$asset.lor.cardkeys[after]
        if (id == undefined)
            id = null
        this.cardSearchQualCode = id
        this.filteredCards = this.filterCards(after)
    }

    parseCards (): void {
        const cards = this.$asset.lor.cards
        for (const card of Object.values<any>(cards)) {
            for (const reg_ind of this.$itertool.range(0, card.regionRefs.length)) {
                const reg = card.regionRefs[reg_ind]
                this.regionLocals[reg] = card.regions[reg_ind]
                if (!this.regions[reg]) this.regions[reg] = {champions: [], abilities: [], units: [], spells: [], landmarks: []}
                let region = this.regions[reg]
                if (card.type == 'Unit' && card.supertype == 'Champion') {
                    region.champions.push(card)
                } else if (card.type == 'Unit') {
                    region.units.push(card)
                } else if (card.type == 'Ability') {
                    region.abilities.push(card)
                } else if (card.type == 'Landmark') {
                    region.landmarks.push(card)
                } else {
                    region.spells.push(card)
                }
            }
        }
        for (const region of Object.values<any>(this.regions)) {
            for (let type of Object.keys(region)) {
                region[type] = region[type].sort((x: any, y: any) => y.cost - x.cost)
            }
        }
    }
    
    getType(card: Record<string, any>): string {
        if (card.type == 'Unit' && card.supertype == 'Champion') {
            return 'Champion'
        } else {
            return card.type
        }
    }

    filterCards(nameS: string): Array<any> {
        const filtered: Array<any> = []
        if (!nameS) {
            for (const v of Object.values<any>(this.$asset.lor.cards)) {
                if (this.getType(v) == 'Champion')
                    filtered.push(v)
            }
            return filtered
        }
        const name = nameS.toLowerCase()
        for (const v of Object.values<any>(this.$asset.lor.cards)) {
            if (v.name.slice(0, name.length).toLowerCase() == name)
                filtered.push(v)
        }
        return filtered
    }

}
</script>
